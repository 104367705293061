import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Link from '../../components/link'
import List from '../../components/list'
import ListItem from '../../components/list-item'
import SEO from '../../components/seo'


export const query = graphql`
  query CountriesSitemapPageQuery($genderId: PostGraphile_Gender!) {
    ...SiteInfo
    results: postgres {
      countries: allCountriesList(
        filter: { genders: { contains: [ $genderId ] }},
        orderBy: NAME_ASC,
      ) {
        ...Country
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { countries } = data.results
  const { entity } = meta

  const page = `${entity.singular} Countries Sitemap`
  const title = `${page} - Sitemaps`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Sitemap',
      slug: links.sitemap.home,
    },
    {
      name: 'Countries',
      slug: links.sitemap.countries,
    }
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    slug: links.sitemap.countries,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <List>
        <ListItem>
          <Link href={links.countries}>All Countries</Link>
        </ListItem>
        {countries.map(country => (
          <ListItem key={country.id}>
            <Link href={country.slug}>{country.name}</Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
